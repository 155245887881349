@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lalezar&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cookie&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&family=Noto+Sans&display=swap'); */
.no-extra {
  max-height: 100vh;
  max-width: 100vw;
}
.rooms-heading {
  padding-left: 7px;
  padding-right: 7px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 200%;
  color: rgb(111, 104, 104);
  cursor: pointer;
  /* padding-bottom: 20px; */
  font-family: "Montserrat", sans-serif;
}
.modern-font {
  font-family: "Montserrat", sans-serif;
}
.light-black {
  color: #585050;
}
.cardcontainer {
  padding: 30px 20px 20px 30px;
  /* color: white; */
}
.roomcard {
  /* margin-top: 15px; */

  width: 280px;
  height: 250px;
  margin: 0px !important;
  /* outline: auto; */
  background-color: rgb(19, 16, 16);
  border-radius: 20px;
  transition: transform 0.2s ease-in-out;
  box-shadow: 0px 1px 15px rgba(140, 129, 129, 0.477);
  cursor: pointer;
}

.roomcard:hover {
  transform: translateY(-12px);
  box-shadow: 0px 4px 30px rgba(140, 129, 129, 0.477);
}

.roomimg {
  width: 280px;
  height: 150px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.cardbody {
  /* border-top-left-radius:30px ; */
  padding: 6px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content:center; */
}
.roomtitle {
  font-family: "Montserrat", sans-serif;
  /* font-weight:bold; */

  font-size: 20px;
}
.descbody {
  width: 245px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}
.roomdesc {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
}

.detailscard {
  box-shadow: 0px 0px 10px rgba(239, 230, 230, 0.477);

  margin: 30px 20px 20px 30px;
  padding: 0px 20px 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* min-width: 40px; */
  max-width: 500px;
  /* width: 370px; */
  /* height: 500px; */
  background-color: rgb(12, 12, 12);
  border-radius: 20px;
  color: bisque;
  border-width: 0px;
}
.detailscardfooter {
  margin-top: 10px;
  /* width:600px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}
.footerbtn {
  /* border-width: 1px;  */
  border-color: bisque;
  /* background-color: rgb(32, 30, 30); */
  border-radius: 20px;
  /* padding: 5px;  */
  color: bisque;
  width: 90px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.detailscontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content:center; */
}
.detailstitle {
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  margin-bottom: 8px;
}
.detailsdesc {
  /* margin: 0; */
  white-space: pre-wrap;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  overflow-y: scroll !important;
  height: 100%;
}
.rulesdesc {
  height: 90%;
}
.detailsheader {
  height: 73vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-scroll {
  /* width: 40vw; */
  /* width: 90%;
    min-width: 200px;
    max-width: 400px; */
  width: 320px;
  min-height: 75px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  /* justify-content: center; */
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 10px;
  margin-top: 10px;
}

.profile-scroll::-webkit-scrollbar {
  display: none;
}

.profile-card {
  padding: 10px;
  background-color: rgb(19, 18, 18);
  color: rgb(104, 100, 100);
  border-radius: 20px;
  width: 70px;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 7px 0px 7px;
  /* box-shadow: 0px 0px 1px rgba(243, 232, 232, 0.3); */
}

.profile-card img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.profile-card p {
  margin: 0;
  font-size: 9px;
  font-weight: 500;
}
.scroll-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 18px;
  height: 27px;
  /* margin: 4px; */
  border-width: 2px;
}
.scroll-btn:hover {
  background-color: rgb(61, 60, 60);
}
.profiletitle {
  font-family: "Montserrat", sans-serif;
  /* font-weight: 200; */
  color: rgb(244, 223, 198);
}
.seperaterline {
  width: 40%;
  border: 1px solid rgb(58, 55, 55);
  margin-bottom: 5px;
}

.tag-card {
  padding: 3px 7px 3px 7px;
  background-color: rgb(27, 25, 25);
  color: rgb(91, 80, 80);
  border-radius: 30px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  margin: 0px 7px 0px 7px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  margin-bottom: 10px;
}
/* .tag-card:hover {
    transform: translateY(-1px);
    box-shadow: 0px 1px 5px rgba(140, 129, 129, 0.477);  
  } */
.tag-card p {
  margin: 0;
  font-size: 9px;
  font-weight: 500;
}
.tag-dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: 0px;
  margin-right: 5px;
}
.tag-scroll {
  max-width: 250px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  /* justify-content: center; */
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* margin-bottom: 10px; */
  margin-top: 3px;
}
.tag-scroll::-webkit-scrollbar {
  display: none;
}
.floating-button {
  z-index: 4;
  position: fixed;
  bottom: 30px;
  right: 40px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: black;
  color: beige;
  font-size: 24px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  box-shadow: 0px 0px 12px rgba(238, 232, 232, 0.3);
}

.floating-button:hover {
  background-color: rgb(21, 20, 20);
}
.createbtn:hover {
  background-color: rgb(10, 104, 10);
  color: white;
  border-width: 0px;
}
.deletebtn:hover {
  background-color: rgb(157, 20, 20);
  border-width: 0px;
}
.taginput {
  min-width: 0px;
  width: 113px;
}
.hoursinput {
  min-width: 0px;
  width: 90px;
  margin-left: 20px;
}

.descinput {
  background-color: rgb(28, 28, 28);
  color: white;
  border: none;
  resize: none;
  caret-color: white;
  border-radius: 10px;
}
.headingtoggle {
  width: 100%;
}
.headingunderline {
  /* border-bottom: 2px solid rgb(58, 55, 55); */
  /* color: rgb(214, 214, 202); */
  border-radius: 10px;

  transform: translateY(-3px);
  box-shadow: 0px 2px 6px rgba(140, 129, 129, 0.477);
}
.rooms-heading:hover {
  border-radius: 10px;
  transform: translateY(-3px);
  box-shadow: 0px 2px 6px rgba(140, 129, 129, 0.477);
}
.detailswrapper {
  /* background-color: red; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  /* height: 95vh;
    width: 100vw; */
  /* max-width: 900px; */
  /* background-color: blue; */

  /* width: 95vw;
    margin-right: 10px; */
}
.joinedwrapper {
  /* background-color: red; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 95vh;
  width: 80vw;
  /* max-width: 900px; */
  /* background-color: blue; */

  /* width: 95vw;
  margin-right: 10px; */
}

@media (max-width: 450px) {
  .joinedwrapper {
    width: 80vw;
    /* top: 50%; */
    left: 41%;
    /* transform: translate(-43%, -50%); */
  }
}
.joinedcard {
  width: 100%;
  /* margin: 30px 20px 20px 30px; */
  /* padding: 0px 20px 15px 20px;  */
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: bisque;
  border-width: 0px;
  /* align-self: flex-end; */
}

.sidebar {
  padding-top: 10px;
  background-color: rgb(12, 12, 12);

  margin-right: 10px;
  box-shadow: 0px 0px 3px rgba(239, 230, 230, 0.477);
  border-radius: 10px 0px 0px 10px;

  flex: 0 0 auto;
  width: 60px;
  height: 85vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* background-color: red; */
}

.verticalbutton {
  /* box-shadow: 0px 0px 2px rgba(239, 230, 230, 0.477);   */
  /* border-radius: 8px; */
  /* width: 30px;
    height: 30px; */
  /* display: flex;
    align-items: center;
    justify-content: center; */
  margin-top: 27px;
  margin-left: 4px;
  cursor: pointer;
  /* font-weight: 100; */
}
.verticalbutton:hover {
  /* box-shadow: 0px 0px 2px rgba(239, 230, 230, 0.477);   */
  transition: transform 0.2s ease-in-out;
  transform: translateY(-3px);
  /* color: black; */
  /* background-color: aliceblue; */
}
.settingsbutton {
  /* margin-top: 27px;
    margin-left: 4px; */
  cursor: pointer;
  /* font-weight: 100; */
}
.settingsbutton:hover {
  /* box-shadow: 0px 0px 2px rgba(239, 230, 230, 0.477);   */
  transition: transform 0.2s ease-in-out;
  transform: translateY(-1px);
  color: rgba(255, 247, 247, 0.477);
  /* background-color: aliceblue; */
}
.main-joined-container {
  /* min-width: 300px; */
  display: flex;
  flex-direction: column;
  width: 100%;
  /* align-items: center; */
  /* justify-content: center; */
  height: 85vh;
  background-color: rgb(12, 12, 12);
  border-radius: 0px 10px 10px 0px;
  box-shadow: 0px 0px 3px rgba(239, 230, 230, 0.477);
}
.top-bar {
  align-self: flex-start;
  font-weight: 900;
  /* background-color: red; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-bottom: 2px solid rgb(58, 55, 55); */
}
.joined-heading {
  font-family: "Montserrat", sans-serif;
  font-size: 27px;
  color: rgb(204, 204, 170);
}
.logo {
  box-shadow: 0px 0px 2px rgba(239, 230, 230, 0.477);
  border-radius: 8px;
  background-size: cover;
  background-image: url("../../public/android-chrome-512x512.png");
  width: 44px;
  height: 44px;
  /* border-radius: 0px; */
}
@media (max-width: 450px) {
  .sidebar {
    width: 52px;
  }
  .logo {
    width: 38px;
    height: 38px;
  }
  .verticalbutton {
    margin-left: 2px;
  }
}
.closebtn {
  position: absolute;
  top: -9px;
  right: 7px;
  color: rgb(64, 64, 58);
  font-size: 24px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}
.closebtn:hover {
  /* background-color: "black"; */
  color: rgb(105, 105, 96);
  font-size: 24px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  /* box-shadow: 0px 0px 12px rgba(238, 232, 232, 0.3); */
}
.inputbar {
  box-shadow: 0px 0px 4px rgba(238, 232, 232, 0.3);
  color: rgba(239, 221, 221, 0.997);
  height: 60px;
  margin: 0px 0px 10px 0px;
  padding: 2%;
  /* width: 10%; */
  /* width: calc(100%-20px); */

  background-color: rgba(104, 95, 95, 0.347);
  /* background-color: black; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  justify-content: space-between;
}
.inputbar textarea {
  background-color: transparent;
  /* background: red; */
  width: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  resize: none;
  color: white;
  caret-color: white;
  font-size: 16px;
}
.inputbar button {
  border: none;
  /* padding: 3px; */

  margin: 0px;
  margin-top: 1px;
  background-color: transparent;
  /* color: rgb(198, 198, 167); */
  color: rgb(236, 232, 207);
}
.inputbar button:hover {
  color: rgb(225, 214, 153);
  /* box-shadow: 0px 0px 4px rgba(238, 232, 232, 0.3); */
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #3b3939;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #464444;
}
.messages {
  overflow-y: scroll;
  width: 75vw;
  /* padding: 10px; */
  height: calc(100%);
  margin-bottom: 1vh;
  border-radius: 10px;
  /* background-color: rgb(21, 21, 21); */
}
.message {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
.messageInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.messageInfo span {
  font-size: 10px;
  color: rgb(90, 90, 83);
}
.timestamp {
  /* margin-top: -3px; */
  /* margin: 0px; */
  /* width: 100%; */
  /* position: relative ; */

  /* right:1px; */
  text-align: right;
  margin-top: 5px;
  color: #464444;
  font-size: 8px;
}
.messageInfo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 3px;
}
.messageContent {
  max-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  /* transition: transform 0.2s ease-in-out; */
}
.owner {
  flex-direction: row-reverse;
}
.owner p {
  max-width: 70%;
  background-color: rgb(240, 233, 163) !important;
  color: black;
  border-radius: 10px 0px 10px 10px !important;
}
.message p {
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  background-color: rgb(28, 26, 26);
  padding: 7px 15px;
  max-width: 100%;
  overflow-wrap: break-word;
  border-radius: 0px 10px 10px 10px;
  white-space: pre-wrap;
  /* add this line */
  text-align: left;
}

.requestcard {
  background-color: rgb(28, 26, 26);
  margin: 3px;
  width: 170px;
  height: 120px;
  box-shadow: 0px 0px 2px rgba(239, 230, 230, 0.477);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease-in-out;
}
.requestcard:hover {
  transform: translateY(-3px);
  box-shadow: 0px 0px 4px rgba(239, 230, 230, 0.477);
}
.requestcard img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-bottom: 3px;
}
.requestcard button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 25px;
  outline: none;
  border: none;
  margin: 4px;
  background-color: rgb(243, 239, 170);
  font-weight: 600;
  font-size: 14px;
  border-radius: 20px;
}
.requestcard button:hover {
  box-shadow: 0px 0px 3px rgba(239, 230, 230, 0.477);
}
.rdecline {
  font-weight: 400 !important;
  background-color: rgb(43, 39, 39) !important;
  color: rgb(255, 250, 250);
}
.requestbtncont {
  margin-top: 2px;
  /* background-color: red; */
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reqcont {
  /* background:red; */
  flex: 1;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 75vw;
  /* padding: 10px; */
}
.online-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 400px;
  z-index: 16;
  /* backdrop-filter: blur(500px) ; */
}
@media (max-width: 450px) {
  .online-popup {
    top: 45%;
    left: 42%;
  }
}
.blur-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background-color: whi; */
  /* background: red; */
  /* opacity: 10px; */
  backdrop-filter: blur(4px);
  z-index: 15;
  /* display: none; */
}

.offline-popup {
  position: fixed;
  top: 93%;
  left: 0;
  width: 100%;

  /* height: 10%; */
  background-color: rgb(19, 18, 18);
  color: #ffffff;
  /* padding: 10px; */
  text-align: center;
  z-index: 99;
}
.offline-popup p {
  font-size: 100%;
}
.studytogether-bar {
  box-shadow: 0px 0px 4px rgba(238, 232, 232, 0.3);
  color: rgba(239, 221, 221, 0.997);
  height: 70px;
  /* width: 500px; */
  margin: 0px 0px 10px 0px;
  padding: 2%;
  /* width: 10%; */
  /* width: calc(100%-20px); */

  background-color: rgba(33, 30, 30, 0.347);
  /* background-color: black; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  justify-content: space-between;
}
.studytogether-bar button {
  box-shadow: 0px 0px 10px rgba(238, 232, 232, 0.3);
  border: none;
  outline: none;
  width: 150px;
  height: 55px;
  border-radius: 30px;
  /* transition: background 1s ease; */
  /* background: linear-gradient(to right, #f00, #00f); */
  /* background: linear-gradient(to right,#e66465, #9198e5) ; */
  background: linear-gradient(to right, rgb(233, 233, 90), white);
  /* background-position: left center; */

  background-size: 200% auto;
  transition: background-position 0.6s ease-in-out;
  transition: color 0.6s ease-in-out;

  font-size: 1em;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  color: rgb(46, 43, 43);
}
@media (max-width: 500px) {
  .studytogether-bar button {
    margin-top: -9px;
    width: 100px;
    font-size: 10px;
  }
  .studytogether-bar {
    height: 80px;
    flex-direction: column-reverse !important;
  }
  .rangetimerinput {
    margin-top: 0px !important;
  }
  .studytogether-bar p {
    margin-top: -11px !important;
    font-size: 12px !important;
    margin-bottom: 11px;
  }
}
.studytogether-bar button:hover {
  background-position: right center;

  color: #100f0f;
  /* background-image: linear-gradient(to right, #f00, #00f); */
}
.studytogether-bar button:disabled {
  /* Styles for disabled button */
  opacity: 0.3;
  cursor: not-allowed;
  /* ... other styles ... */
}
.studytogether-bar p {
  margin-top: 0px;
  margin-left: 15px;
  font-size: 1em;
  font-family: "Montserrat", sans-serif;
}
.rangetimercont {
  margin-right: 15px;
  width: 210px;
}
.rangetimerinput {
  margin-top: 13px;
  /* width: 110px !important; */
}
.rangetimerinput::-webkit-slider-thumb {
  background-color: rgb(220, 203, 94); /* Change the thumb color */
}

.rangetimerinput::-moz-range-thumb {
  background-color: rgb(94, 78, 78); /* Change the thumb color */
}

.rangetimerinput::-webkit-slider-runnable-track {
  background-color: rgb(57, 57, 61); /* Change the track color */
}

.rangetimerinput::-moz-range-track {
  background-color: rgb(87, 87, 95); /* Change the track color */
}

.room-notification {
  padding: 2%;
  padding-left: 8%;
  /* padding-right: 8%; */
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.room-notification:hover {
  background-color: #1f1e1e;
}
.room-notification p {
  padding-left: 5px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 13px !important;
  color: white;
}
.room-notification button {
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 25px;
  outline: none;
  /* border: ; */
  /* border:none; */
  border-color: beige;
  color: beige;
  margin: 4px;
  /* background-color: rgb(243, 239, 170); */
  font-weight: 600;
  font-size: 14px;
  border-radius: 20px;
}
.room-notification-footer {
  width: 190px;
  margin-top: -2px;
  padding-right: 6%;
  display: flex;
  justify-content: space-between;
}
.acceptroom:hover {
  background-color: green;
}
.rejectroom:hover {
  background-color: red;
}
.room-notification button:hover {
  /* box-shadow: 0px 0px 3px rgba(239, 230, 230, 0.477);   */
  /* color: black; */
  font-weight: 500;
  border: none;
  outline: none;
}
.noti-toast {
  position: fixed;
  top: 10%;
  z-index: 99;
  left: 3%;
  outline: none;
  /* border-width: 10px; */
  /* border-color: white; */
}
/* .toast-dark{
  background-color: #100f0f;
}
.toast-dark-body{
  background-color: #1a1919;
} */
.redbutt {
  /* float: right; */
  position: absolute;
  bottom: 15px;
  right: 18px;

  /* float: right; */
  /* left:100px; */
  /* float: bottom; */

  background: red;
  width: 150px;
  height: 50px;
  border-radius: 40px;
  outline: none;
  offset: none;
  border: none;
  font-weight: 700;
  color: white;
  transition: transform 0.2s ease-in-out;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
.redbutt:hover {
  /* background: rgb(229, 65, 65); */
  box-shadow: 0px 1px 8px rgba(140, 129, 129, 0.477);
  transform: translateY(-3px);
}
.msg-bottom-cont {
  margin-top: -4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ownerdel {
  visibility: hidden;
  background: none;
  margin: 0px;
  padding: 0px;
  /* margin: -15px; */
  border-radius: 40px;
  outline: none;
  offset: none;
  border: none;
  font-weight: 700;
  opacity: 0;
  color: rgb(35, 30, 30);
  /* transition: transform 0.2s ease-in-out; */
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  font-size: 16px;
  margin-right: -3px;
  margin-left: 4px;
  /* margin-bottom: 2px; */
  /* font-family: 'Montserrat', sans-serif; */
}
.ownerdel:hover {
  color: rgb(237, 9, 9);
  /* box-shadow: 0px 1px 8px rgba(140, 129, 129, 0.477); */
  /* transform: translateY(-1px); */
}
.messageContent:hover .ownerdel {
  visibility: visible;
  opacity: 1;
}
.deletedmsg {
  color: #4e4b4b;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
}
.theme {
  border: 2px solid rgba(177, 177, 167, 0.407);
  width: 80px;
  height: 59px;
  border-radius: 6%;
  display: inline-block;
  margin-right: 12px;
  margin-left: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}
.theme:hover {
  background-color: rgba(203, 203, 189, 0.153);
}
.theme-img {
  width: 75px;
  height: 55px;
  /* border: beige; */
  /* border-width: 10px; */
}
.theme-text {
  margin-top: 2px;
  font-size: small;
  /* font-family: 'Montserrat', sans-serif; */
}
.grabbar {
  height: 7px;
  cursor: grab;
  background-color: #dccfcf;
  width: 100px;
  border-radius: 5px;
}
/* .grabbar:hover{
  transition: transform 0.2s ease-in-out;
  transform: translateY(-3px);
} */
.grabicon {
  /* padding-right: 20px; */
  /* visibility: hidden; */
  position: absolute;
  left: 10px;
  cursor: grab;
}
.grabicon:hover {
  /* padding-right: 20px; */
  /* visibility:visible; */
}
.header {
  /* background: red; */
  width: 360px;
  height: 10px;
}
@keyframes breathe {
  0% {
    transform: scale(1);
  }

  21% {
    transform: scale(2.2);
  }

  42% {
    transform: scale(2.2);
    /* //8 sec of exhale =>(8/19)*100 */
  }

  100% {
    transform: scale(1);
  }
}
.breathing-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: radial-gradient(white, #c4c8e4);
  animation: breathingCycle 19s ease-in-out infinite;
}
.breathe-container {
  border-radius: 50%;
  background: radial-gradient(#d0d2e0, #8e97dd, #4856ec);
  width: 220px;
  height: 220px;
  margin-bottom: 13px;
  /* font-size: ; */
}
.breathing-widget {
  position: relative;
  z-index: 10;
  border-radius: 9%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(14, 14, 14);
  width: 310px;
  height: 320px;
  /* margin-bottom: 20px; */
  /* background: radial-gradient(white, rgb(95, 223, 95)); */
}
.breathe-title {
  margin-bottom: 3px;
  color: white;
  font-size: 25px;
}
.breathe-status {
  /* display: fixed; */
  font-weight: bold;
  font-size: 20px;
  /* margin-top: -10px; */
  /* font-family: 'Montserrat', sans-serif; */

  color: #929de6;
  /* border-color: rgb(233, 237, 232); */
  cursor: pointer;
}
.breathe-status:hover {
  color: #98a3e9;
  /* border-color: rgb(233, 237, 232); */
  cursor: pointer;
}
.breathe-duration {
  font-size: 10px;
  text-align: center;
  border-radius: 5px;
  width: 45px;
  border: 2px solid #ebd8d8;
  margin-bottom: 8px;
  background: transparent;
  /* width: 20px; */
  cursor: pointer;
}
.breathe-duration:hover {
  /* border: 2px solid #ebd8d8; */
  background: #ebd8d813;
}
.navbox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 12px;
  gap: 1px;
  margin-left: 20px;
  /* border: 2px solid #ebd8d8; */
  /* padding: 3px; */
  border-radius: 6px;
}
.navbox:hover {
  /* background: #4240403c; */
  /* color: grey; */
}
@media (max-width: 756px) {
  .navbox {
    margin-left: -60px;
  }
  .navbox:hover {
    background: none;
  }
}

/* Sidebar.css */

.widget-sidebar {
  box-shadow: 0px 0px 3px rgba(239, 230, 230, 0.477);

  position: fixed;
  top: 23%;
  left: -200px; /* Initial position outside the viewport */
  width: 70px;
  height: 425px;
  /* height: 310px; */
  background-color: black;
  color: beige;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: left 0.3s ease;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 13;
}

.widget-sidebar.open {
  left: 0;
}
.widgets-drawer {
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  width: 60px;
  height: 100%;
  /* background: blue; */
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}
.widgettogglebutton {
  width: 20px;
  height: 25px;
  border: none;
  cursor: pointer;
  transition: left 0.3s ease;
  color: beige;
  position: absolute;
  left: 70px;
  /* margin-right: -200px; */
}
.widgettogglebutton:hover {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  color: beige;
}
.widgettogglebutton.close {
  left: 200px;
  /* left: 0px; */
}
.widget-content {
  /* flex: 1;
  padding: 16px; */
}
.widget-logo {
  box-shadow: 0px 0px 4px rgba(239, 230, 230, 0.477);
  border-radius: 8px;
  background-size: cover;
  /* background-image: url('../../public/android-chrome-512x512.png'); */
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  padding-bottom: 2px;
  margin-top: 15px;
  cursor: pointer;
  /* border-color: white; */
  /* border-width: 5px; */
}
/* @media (max-width: 450px) {
  .widget-sidebar{
      width: 52px;
  }
  .widget-logo{
    width: 38px;
    height: 38px;
  }
} */
.widget-logo:hover {
  background-color: rgba(244, 240, 240, 0.26);
}
.widget-logo.open {
  /* background: rgb(237, 75, 75); */
  /* background: green; */
  background-color: rgba(244, 240, 240, 0.26);
}
.hide-toggle {
  transition: opacity 0.2s ease, max-height 0.9s ease-out;
  opacity: 0;
  max-height: 0px;
  margin: 0px !important;
  padding: 0px !important;
  /* position: absolute; */
  /* display: none !important; */
  /* border-width: 0px !important; */
  /* background: red; */
  /* width: 0px; */
  /* display: none; */
  overflow: hidden;
  /* transition: height 1s ease; */
}
.open-toggle {
  /* background: red; */
  opacity: 1;
  max-height: 1000px;
  transition: opacity 2s ease, max-height 3s ease-out;
  /* transition: height 1s ease; */
}
.nopad {
  padding: 0px !important;
}
.minimax {
  visibility: hidden;
  /* position: absolut; */
  /* top: 6px; */
  /* right: -255px; */
  margin-left: 315px;
  margin-top: 10px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.card:hover .minimax {
  visibility: visible;
}
/* .card:hover .grabbar{
  visi
} */
.hide-nav {
  transition: opacity 0.6s ease, max-height 0.6s ease-out;
  opacity: 0;
  max-height: 0px;
}
.open-nav {
  /* background: red; */
  opacity: 1;
  max-height: 1000px;
  transition: opacity 0.6s ease, max-height 0.6s ease-out;
  /* transition: height 1s ease; */
}
.cont-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -80vh;
}
.otakufy-heading {
  /* font-family: 'Roboto', sans-serif; */
  /*  */
  font-family: "Montserrat", sans-serif;
  /* font-family: "Cookie", cursive; */
  /* font-family: "Lalezar", system-ui; */
  /* font-weight: 500; */
  /* font-style: normal; */
  color: white;
  font-weight: bold;
  /* font-size: 7em; */
  /* text-align: center; */
  position: absolute;
  margin-top: 20vh;

  /* margin-left: 20vw;
  margin-right: 20vw; */
}
.showcardcontainer {
  padding: 30px 20px 20px 30px;
  /* color: white; */
}
.showcard {
  /* margin-top: 15px; */

  width: 350px;
  height: 380px;
  margin: 0px !important;
  /* outline: auto; */
  background-color: rgb(19, 16, 16);
  border-radius: 20px;
  transition: transform 0.2s ease-in-out;
  box-shadow: 0px 1px 15px rgba(140, 129, 129, 0.477);
  cursor: pointer;
}

.showcard:hover {
  /* opacity: 0.2; */
  transform: translateY(-12px);
  box-shadow: 0px 4px 30px rgba(140, 129, 129, 0.477);
}

.showcardimg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 350px;
  height: 380px;
  border-radius: 20px;
  opacity: 0.6;
  transition: opacity 0.4s ease;
  filter: blur(2px);

  /* background-image: url("./images/homeimge4.webp"); */
  /* background-color: rgba(255, 0, 0, 0.199); */
}
.showcardimg:hover {
  opacity: 1;
  filter: blur(0px);
  transition: opacity 0.4s ease;
}

.showcardbody {
  /* border-top-left-radius:30px ; */
  padding: 6px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content:center; */
}
.scrollcardimg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 321px;
  height: 300px;
  border-radius: 4px;
  /* opacity: 0.6; */
  /* transition: opacity 0.4s ease; */
  /* filter: blur(2px) ; */

  /* background-image: url("./images/homeimge4.webp"); */
  /* background-color: rgba(255, 0, 0, 0.199); */
}
.focuschart {
  min-width: 370px;
  /* min-height: 200px; */
  /* min-height: 300px; */
  padding: 15px;
  background-color: rgb(19, 16, 16);
  border-radius: 20px;
  transition: transform 0.2s ease-in-out;
  box-shadow: 0px 1px 15px rgba(140, 129, 129, 0.477);
}
.todo-container {
  width: 350px;
  height: 550px;
  border-color: rgb(19, 18, 18);
  border-width: 2px;
  padding: 15px;
  background-color: rgb(19, 16, 16);
  border-radius: 20px;
  /* transition: transform 3s ease-in-out; */
  box-shadow: 0px 1px 15px rgba(140, 129, 129, 0.477);
}
.space-container {
  width: 370px;
  /* height: 550px; */
  border-color: rgb(19, 18, 18);
  border-width: 2px;
  /* padding: 15px; */
  background-color: rgb(19, 16, 16);
  /* background-color: #292323; */
  /* #131010 */
  border-radius: 20px;
  /* transition: transform 3s ease-in-out; */
  box-shadow: 0px 1px 15px rgba(140, 129, 129, 0.477);
}

.smallstatcard {
  min-width: 230px;
  max-height: 140px !important;
}
.fontdull {
  color: #dccfcf6f !important;
  margin-left: 15px;
}
.neu-container {
  background: #2b2727;
}
.neumorphism {
  border-radius: 61px;
  background: linear-gradient(145deg, #141111, #110e0e);
  box-shadow: 5px 5px 13px #080606, -5px -5px 13px #1e1a1a;
}
.neumorphism:hover {
  border-radius: 61px;
  background: linear-gradient(145deg, #110e0e, #141111);
  box-shadow: 5px 5px 13px #080606, -5px -5px 13px #1e1a1a;
}
.neumorphism-clicked {
  border-radius: 61px;
  background: linear-gradient(145deg, #110e0e, #141111);
  box-shadow: 5px 5px 13px #080606, -5px -5px 13px #1e1a1a;
}
.gradient-text {
  /* background:#000  !important; */
  background-image: linear-gradient(to right, #000,#a94193, #c23aa4) !important; /* Define your gradient colors */
  -webkit-background-clip: text; 
  color: transparent !important; /* Make the text transparent */
}
