@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

/* * > * {
    
    margin: 0;
} */

:root{
    --color-dark: #2C3131;
    --color-light: #F3F3F2;
}

#main{
    
        /* display: flex; */
        justify-content: center;
        align-items: center;
        height: 100vh;
}

.board {
    /* background: red; */
    width: 70vw;
    /* height: 100%; */
    text-align: center;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    max-width: 650px;
}
@media (max-width:500px) {
    .board{
        width:99vw;
    }
}

.board .leaderboard{
    /* overflow-y: scroll; */
    margin-bottom: 1em;
    font-family: "Poppins", cursive;
    font-weight: bold;
    /* margin-right: 2em ; */
}

.board .duration{
    color: #F3F3F2;
    display: flex;
    justify-content: center;
    gap: 1em;
}

.board .duration > button{

    border: 1px solid var(--color-light);
    color: #F3F3F2;
    padding: .5em 2em;
    border-radius: 50px;
    background-color: transparent;
    cursor: pointer;
    min-width: 110px;
}

.board .duration > button:hover{
    background-color: var(--color-light);
    color: var(--color-dark);
    font-weight: 600;
}
.clicked{
    background-color: var(--color-light) !important;
    color: var(--color-dark) !important;
    font-weight: 600;
}

.active{
    background-color: var(--color-dark);
    color: var(--color-light);
    /* background: #000; */
    /* font-weight: 700 !important; */
}

#profile{
    /* display: flex; */
    /* color: rgb(63, 111, 154); */
    margin-top: 3em;

}

#profile .flex{
    width: 100%;
    /* color: ; */
    display: flex;
    /* padding: 1%; */
    margin-left: 3%;
    margin-right: 3%;
    padding-left: 3%;
    padding-right: 3%;
    justify-content: space-between;
    gap: 1em;
    text-align: left;
    border-radius: 10px;
    margin-bottom: 2em;
    background-color: rgba(13, 13, 13, 0.24);
    overflow: hidden;
    box-shadow: 0px 0px 20px rgb(29, 27, 27);

}
#profile .flex:hover{
    background-color: rgb(14, 14, 14);
    box-shadow: 0px 0px 4px rgb(120, 113, 113);
}

#profile .flex .item{
    /* background: red; */
    display: flex;
    font-size: 100%;
    align-items: center;
    justify-content: space-between;
}

#profile .flex img{
    width: 20%;
    border-radius: 50%;
}

#profile .flex .info{
   padding: 1em;
   /* align-; */
}

.text-dark{
    color: var(--color-light);
}
.name{
    font-size: 1.7em;
    max-width: 1em !important;
    /* height: fit-content; */
}
#ScrollTopBtn {
    position: fixed;
    bottom: 0.7em;
    right: 0.7em;
    /* width: 40px; */
    padding-right: 0.5em;
    padding-left: 0.5em;

    background-color: #1f1c1c;
    color: #fff;
    /* padding: 10px 20px; */
    border: none;
    cursor: pointer;
    font-size: 2em;
    border-radius: 100%;
    box-shadow: 0px 0px 10px rgba(237, 237, 237, 0.477);  
  }
